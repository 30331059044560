<template>
    <div class='login-bg'>
        <div class='login'>
            <transition name='slide-fade'>
                <el-card class="login-box" v-if="show" v-loading="loading">
                    <el-form @submit.prevent.native="login" >
                        <div style="text-align:center; margin-top:40px">
                            <img src="../assets/logo-pitcar.png" class="img-responsive img-logo" />
                        </div>                    
                        <!-- {{password}} -->
                        <!--<el-form-item  style="margin-top:20px;">
                            <el-input type="password" v-model="password"></el-input>
                        </el-form-item> -->
                        <el-row class="rand-parola">
                            <el-col :span="14" :offset="5" >
                                <el-input :disabled="true" type="password" v-model="password"></el-input>
                            </el-col>
                            
                        </el-row>
                        
                        
                        <el-row :gutter='15' class='butoane' style="margin-top:20px"> 
                            <el-col :span="8">
                                <el-button class='full-width' @click='add("1")'>1</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button class='full-width' @click='add("2")'>2</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button  class='full-width' @click='add("3")'>3</el-button>
                            </el-col>

                            <el-col :span="8">
                                <el-button class='full-width' @click='add("4")'>4</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button class='full-width' @click='add("5")'>5</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button  class='full-width' @click='add("6")'>6</el-button>
                            </el-col>

                            <el-col :span="8">
                                <el-button class='full-width' @click='add("7")'>7</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button class='full-width' @click='add("8")'>8</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button  class='full-width' @click='add("9")'>9</el-button>
                            </el-col>

                            <el-col :span="8">
                                <el-button  class='full-width' ></el-button>
                            </el-col>

                            <el-col :span="8">
                                <el-button  class='full-width' @click='add("0")'>0</el-button>
                            </el-col>

                            <el-col :span="8">
                                <el-button  class='full-width' @click='del()'> DEL </el-button>
                            </el-col>
                        </el-row>

                        <el-col :span="24" style="margin-top:20px">
                            <el-form-item>
                                <el-button style="background-color:black; color:#E6A23C" native-type="submit" class='full-width' > LOGIN </el-button>
                            </el-form-item>
                        </el-col>

                    </el-form>
                </el-card>
            </transition>
        </div>
    </div>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import Vue from 'vue'

    export default {
        name: "login",
        data () {
            return {
                show: false,
                loading: false,
                password: "",
                isActive:''
            }
        },
        methods: {
            login: async function() {
                var response = await this.post('login', { password: this.password} );    

                if(!response.HasBearer) {
                    this.$message.error('Nu aveti drept de acces!');
                    return
                }                   

                if( response.Token != '' ){
                    settings.set_token    ( response.Token    );
                    settings.set_drepturi ( response.Rights   );
                    settings.set_user_type( response.UserType );
                    settings.set_user_name( response.UserName );

                    //
                    settings.set_tip_utilizator( response.Utilizator.TipUtilizator );
                    //
                    if(response.Utilizator.TipUtilizator == 'admin') this.$router.push("/crm");
                    if(response.Utilizator.TipUtilizator == 'office') this.$router.push("/crm");
                    if(response.Utilizator.TipUtilizator == 'mecanic') this.$router.push("/fisa_intrare_produse");                                        
                }else{
                    this.$message.error('Date gresite');
                }
            },
            post: async function(url, args={}){
                this.loadingVisible      = true;
                var             response = await this.$http.post(url, args );
                this.loadingVisible      = false;
                if( settings.verify_response(response) )
                {
                    return response.body;
                }
            },
            add(nr){               
                this.password += nr;             
            },
            del(){
                // if( this.password.length > 0 )
                // {
                //     this.password = this.password.toString().substring( 0, this.password.length - 1 );
                // }     
                this.password = ""           
            }
        },
        mounted: function() {
            this.show   = true;
        }
    };
</script>

<style lang="less" scoped>

    .login-bg{
        background-color: #E6A23C
    }
    .img-logo {
        max-height: 60px;
    }
    .login-box {
        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;
        
        margin: auto;
        width: 500px;
        height: 550px;
    }

    .slide-fade-enter-active {
        transition: all 1.3s ease;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateY(250px);
        opacity: 0;
    }
    
    .butoane{
        button
        {
            height: 50px;
            margin: 10px 0px;  

            &:hover{
                background-color:rgba(247, 192, 14, 0.144) !important;   
                border-color: rgba(247, 192, 14, 0.144) !important;
                color:black;
            }
            &:active{
                background-color:black !important;   
                border-color: black !important;
                color:#E6A23C !important;
                font-weight: 700;
                outline:0; 
            }
            &:focus{
                background-color:white;  
                outline: none;
                box-shadow: none;
                border-color:#DCDFE6;
                color:#606266;
            }
        }                      
    }

   
    

</style>

<style lang="less">
     .rand-parola{
        margin-top:20px;
        .el-input.is-disabled .el-input__inner{
            font-size:30px;
        }
    }
</style>
